<template>
    <div class="help">
        <!-- heading numbering as per: -->
        <!-- https://2ality.com/2012/01/numbering-headingshtml.html -->
        <h1>Network Black Hole Help</h1>

        <h2 class="nocount">Contents</h2>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>

        <a name="howitworks" />
        <h2>How it works - Overview</h2>


        <a name="terms" />
        <h2>Terms and Building Blocks</h2>
        <h3>Filter</h3>

        <h3>IP Entry</h3>
        
        <h3>Profile</h3>

        <h3>BGP Peer</h3>


        <a name="features" />
        <h2>Features</h2>

        <a name="search" />
        <h3>Address search</h3>
        You can use the search field at the top edge to search for any IP address or IP network. The search field accepts any valid IP address (in dotted decimal format for IPv4 or any standard format for IPv6) and may include a slash followed by prefix length. The following are examples of valid input:
        <ul>
            <li>1.1.1.1</li>
            <li>1.1.1.1/32</li>
            <li>fe80::</li>
        </ul>

        Note that the search matches the searched term using binary maths, not string matching. When searching for a prefix the whole prefix must be covered by a filter.

        Text-based search and partial input is not supported. The following are examples of invalid input:
        <ul>
            <li>1.1.1</li>
            <li>192.168</li>
            <li>fe80</li>
        </ul>

        <h2></h2>

    </div>
</template>

<script>
import { onMounted, getCurrentInstance } from "@vue/composition-api";

export default {
    name: "HelpView",
    components: {},
    setup() {
        const { emit } = getCurrentInstance();


        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Help",
                    disabled: false,
                    href: "/help",
                },
            ]);
        });

        return {};
    },
};
</script>
